<template>
  <section>
    <div class="form--group" :class="{ 'has-error': validation.hasError('selectedPropertyType') }">
      <div class="left--col">
        <label>
          <span>{{ $t('general.propertyType') }}</span>
          <span class="required">*</span>
        </label>
      </div>
      <div class="right--col">
        <v-tabs class="tabs--property-type" v-model="tab" center-active>
          <v-tab
            v-for="propTypeCategory in propertyTypeCategories"
            :key="`v-tab-${propTypeCategory.id}`"
            :href="`#tab-${propTypeCategory.id}`"
            class="tab--content"
          >
            {{ $t(`general.${propTypeCategory.name}`) }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab" touchless class="tabs--items-property-type mt-2">
          <v-tab-item
            v-for="propTypeCategory in propertyTypeCategories"
            :key="`v-tab-item-${propTypeCategory.id}`"
            :value="`tab-${propTypeCategory.id}`"
          >
            <div class="row">
              <div class="col-6">
                <div
                  v-for="(propertyType, index) in formattedPropertyTypes[propTypeCategory.id]"
                  :key="propertyType.id"
                  class="w-100"
                  role="group"
                  aria-label="..."
                >
                  <button
                    v-if="index % 2 === 0"
                    :class="{ 'btn-primary': propertyType.selected }"
                    @click.prevent="propertyTypeClicked(propertyType.id)"
                    class="btn btn-light w-100 border-bottom"
                  >
                    {{ propertyType.name }}
                  </button>
                </div>
              </div>
              <div class="col-6">
                <div
                  v-for="(propertyType, index) in formattedPropertyTypes[propTypeCategory.id]"
                  :key="propertyType.id"
                  class="w-100"
                  role="group"
                  aria-label="..."
                >
                  <button
                    v-if="index % 2 !== 0"
                    :class="{ 'btn-primary': propertyType.selected }"
                    @click.prevent="propertyTypeClicked(propertyType.id)"
                    class="btn btn-light w-100 border-bottom"
                  >
                    {{ propertyType.name }}
                  </button>
                </div>
              </div>
            </div>
          </v-tab-item>
        </v-tabs-items>
        <span class="val-error" v-if="validation.hasError('selectedPropertyType')">{{
          validation.firstError('selectedPropertyType')
        }}</span>
      </div>
    </div>
  </section>
</template>

<script>
/* eslint-disable vue/no-use-v-if-with-v-for */

import { mapGetters, mapState } from 'vuex';
import { Validator } from 'simple-vue-validator';
export default {
  components: {},
  data() {
    return {
      activeTab: null,
      isSelected: true,
      tab:
        'tab-' +
        (this.propertyTypeCategories && this.propertyTypeCategories[0]
          ? this.propertyTypeCategories[0].id
          : ''),
    };
  },
  computed: {
    ...mapState({
      propertyTypeCategories: (state) => state.v2.spaceManagerRequest.propertyTypeCategories,
      propertyTypeCategoryGroups: (state) =>
        state.v2.spaceManagerRequest.propertyTypeCategoryGroups,
    }),
    ...mapGetters({
      formattedPropertyTypes: 'v2/spaceManagerRequest/formattedPropertyTypes',
      selectedPropertyType: 'v2/spaceManagerRequest/selectedPropertyType',
      filteredPropertyTypeCategories: 'v2/spaceManagerRequest/filteredPropertyTypeCategories',
    }),
    selectedPropertyTypeCategoryGroup: {
      get() {
        return this.$store.getters['v2/spaceManagerRequest/selectedPropertyTypeCategoryGroup'];
      },
      set(value) {
        this.$store.commit(
          'v2/spaceManagerRequest/SET_SELECTED_PROPERTY_TYPE_CATEGORY_GROUPS',
          value,
        );
      },
    },
    selectedPropertyTypeCategory: {
      get() {
        return this.$store.getters['v2/spaceManagerRequest/selectedPropertyTypeCategory'];
      },
      set(value) {
        this.$store.commit('v2/spaceManagerRequest/SET_SELECTED_PROPERTY_TYPE_CATEGORIES', value);
      },
    },
  },
  methods: {
    propertyTypeClicked(propertyTypeId) {
      this.$store.dispatch('v2/spaceManagerRequest/changePropertyType', propertyTypeId);
    },
    validate: function () {
      return this.$validate().then(
        function (success) {
          return !!success;
        }.bind(this),
      );
    },
    selected() {
      this.isSelected = !this.isSelected;
    },
  },
  validators: {
    selectedPropertyType(value) {
      return Validator.value(value).required(
        this.$i18n.t('errors.addListing.propertyType.requiredSpaceManager'),
      );
    },
  },
};
</script>
